<template>
  <div class="form-prototype">
    <h2>CRM Identification</h2>

    <div class="owner-add-container">
      <TextInput
        ref="ownerAdd"
        inputLabel="New Owner"
        inputName="owner"
        inputType="text"
        inputClass="dashboard"
        v-model="newOwner"
        @on-enter="addOwner()"
      />

      <MercalaButton
        @button-event="addOwner()"
        label="Add Owner"
        type="dashboard-ok"
      />
    </div>

    <div class="owners-container">
      <div
        v-for="owner in owners"
        :key="owner.id"
        :class="ownerFormClasses(owner.ownerTabOpen)"
      >
        <h3 class="owner-title" @click="toggleOwnerTab(owner)">
          {{ owner.fullName }}
        </h3>

        <!-- Owner Type -->
        <div class="owner-type-container">
          <label :id="getInputId('owner-type', owner.id)">
            <input
              :id="getInputId('owner-type', owner.id)"
              type="radio"
              :value="0"
              :name="getInputId('owner-type', owner.id)"
              v-model="owner.personType"
            />
            <span> Natural Person </span>
          </label>

          <label :id="getInputId('owner-type', owner.id)">
            <input
              :id="getInputId('owner-type', owner.id)"
              type="radio"
              :value="1"
              :name="getInputId('owner-type', owner.id)"
              v-model="owner.personType"
            />
            <span> Legal Entity </span>
          </label>
        </div>

        <!-- Name Section -->
        <div class="form-container">
          <h3>Names</h3>
          <div class="form">
            <TextInput
              :ref="getInputId('owner-first-name', owner.id)"
              inputLabel="Given name(s)"
              :inputName="getInputId('owner-first-name', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.firstName"
            />

            <TextInput
              :ref="getInputId('owner-last-name', owner.id)"
              inputLabel="Last name(s)"
              :inputName="getInputId('owner-last-name', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.lastName"
            />

            <TextInput
              :ref="getInputId('owner-nickname', owner.id)"
              inputLabel="Nickname"
              :inputName="getInputId('owner-nickname', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.nickname"
            />
          </div>
        </div>

        <!-- Phone Section -->
        <div class="form-container">
          <h3>Phone numbers</h3>
          <div class="form">
            <TextInput
              :ref="getInputId('owner-home-phone', owner.id)"
              inputLabel="Home phone"
              :inputName="getInputId('owner-home-phone', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.homePhone"
            />

            <TextInput
              :ref="getInputId('owner-mobile-phone', owner.id)"
              inputLabel="Mobile phone"
              :inputName="getInputId('owner-mobile-phone', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.mobilePhone"
            />

            <TextInput
              :ref="getInputId('owner-business-phone', owner.id)"
              inputLabel="Business phone"
              :inputName="getInputId('owner-business-phone', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.businessPhone"
            />
          </div>
        </div>

        <!-- Email Section -->
        <div class="form-container">
          <h3>Email addresses</h3>
          <div class="form">
            <TextInput
              :ref="getInputId('owner-personal-email', owner.id)"
              inputLabel="Personal email"
              :inputName="getInputId('owner-personal-email', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.personalEmail"
            />

            <TextInput
              :ref="getInputId('owner-business-email', owner.id)"
              inputLabel="Bussines email"
              :inputName="getInputId('owner-business-email', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.businessEmail"
            />
          </div>
        </div>

        <!-- Source of income Section -->
        <div class="form-container">
          <h3>Source of income</h3>
          <div class="form">
            <TextInput
              :ref="getInputId('owner-employer', owner.id)"
              inputLabel="Employer"
              :inputName="getInputId('owner-employer', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.employer"
            />

            <TextInput
              :ref="getInputId('owner-occupation', owner.id)"
              inputLabel="Occupation"
              :inputName="getInputId('owner-occupation', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.occupation"
            />
          </div>
        </div>

        <!-- Origins Section -->
        <div class="form-container">
          <h3>Origins</h3>
          <div class="form">
            <DatePicker
              :ref="getInputId('owner-date-of-birth', owner.id)"
              :inputName="getInputId('owner-date-of-birth', owner.id)"
              inputLabel="Date of birth"
              inputClass="dashboard"
              v-model="owner.dateOfBirth"
            />

            <TextInput
              :ref="getInputId('owner-place-of-birth', owner.id)"
              inputLabel="Place of birth"
              :inputName="getInputId('owner-place-of-birth', owner.id)"
              inputType="text"
              inputClass="dashboard"
              v-model="owner.placeOfBirth"
            />

            <SelectInput
              :ref="getInputId('owner-country-of-birth', owner.id)"
              inputLabel="Country of birth"
              :inputName="getInputId('owner-country-of-birth', owner.id)"
              :options="countries"
              v-model="owner.countryOfBirth"
            />
          </div>
        </div>

        <!-- Identification Section -->
        <div class="form-container">
          <h3>Identification</h3>
          <div class="form">
            <!-- Passport -->
            <div class="form-column">
              <TextInput
                :ref="getInputId('owner-passport-number', owner.id)"
                inputLabel="Passport number"
                :inputName="getInputId('owner-passport-number', owner.id)"
                inputType="text"
                inputClass="dashboard"
                v-model="owner.passportNumber"
              />
              <DatePicker
                :ref="getInputId('owner-passport-expiration', owner.id)"
                :inputName="getInputId('owner-passport-expiration', owner.id)"
                inputLabel="Expiration date"
                inputClass="dashboard"
                v-model="owner.passportExpiration"
              />
            </div>
            <!-- Personal ID -->
            <div class="form-column">
              <TextInput
                :ref="getInputId('owner-personal-number', owner.id)"
                inputLabel="Driver's license ? ID card number"
                :inputName="getInputId('owner-personal-number', owner.id)"
                inputType="text"
                inputClass="dashboard"
                v-model="owner.personalIdNumber"
              />
              <DatePicker
                :ref="getInputId('owner-personal-expiration', owner.id)"
                :inputName="getInputId('owner-personal-expiration', owner.id)"
                inputLabel="Expiration date"
                inputClass="dashboard"
                v-model="owner.personalIdExpiration"
              />
            </div>
          </div>
        </div>

        <!-- Email Section -->
        <!-- Email Section -->
      </div>
    </div>

    <div class="form-tools">
      <MercalaButton
        @button-event="exportOwners()"
        label="Export owners"
        type="dashboard-ok"
      />
    </div>

    <div v-if="xmlResult" class="xml-result">
      <pre>{{ xmlResult }}</pre>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
const js2xmlparser = require("js2xmlparser");

class Owner {
  constructor(_id, _newName) {
    this.id = _id;
    this.personType = 0;
    this.ownerTabOpen = false;

    // name
    const splitName = _newName.split(" ");
    let lastNames = "";
    if (splitName.length > 1) {
      lastNames = splitName.slice(1).join(" ");
    }

    this.firstName = splitName[0];
    this.lastName = lastNames;
    this.nickname = "";
    this.fullName = `${this.firstName} ${this.lastName}`;

    // Phonenumbers
    this.homePhone = "";
    this.mobilePhone = "";
    this.businessPhone = "";

    // Emails
    this.personalEmail = "";
    this.businessEmail = "";

    // Source of income
    this.employer = "";
    this.occupation = "";

    // Origins
    this.dateOfBirth = "";
    this.placeOfBirth = "";
    this.countryOfBirth = "";

    // Identification
    this.passportNumber = "";
    this.passportExpiration = "";
    this.passportImage = null;

    this.personalIdNumber = "";
    this.personalIdExpiration = "";
    this.personalIdImage = "";
  }
}

// import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "FormPrototype",
  components: {
    TextInput: () => import("@/components/form/TextInput"),
    MercalaButton: () => import("@/components/form/MercalaButton"),
    DatePicker: () => import("@/components/form/DatePicker"),
    SelectInput: () => import("@/components/form/SelectInput"),
  },
  watch: {
    owners: {
      deep: true,
      handler: (newOwners, _old) => {
        for (const owner of newOwners) {
          owner.fullName = `${owner.firstName} ${owner.lastName}`;
        }
      },
    },
  },
  data() {
    return {
      ownerId: 0,
      newOwner: "",
      owners: [],
      countries: [
        { name: "", val: "" },
        { name: "Aruba", val: "Aruba" },
        { name: "Bonaire", val: "Bonaire" },
        { name: "Curacao", val: "Curacao" },
      ],
      xmlResult: "",
    };
  },
  methods: {
    addOwner() {
      if (this.newOwner !== "") {
        const addOwner = new Owner(this.ownerId, this.newOwner);
        this.owners.push(addOwner);
        this.$refs.ownerAdd.setValue("");
        this.newOwner = "";

        // Set next owner ID
        this.ownerId++;
      }
    },
    toggleOwnerTab(owner) {
      owner.ownerTabOpen = !owner.ownerTabOpen;
      // Set initial name if not filled yet
      if (
        this.$refs[
          this.getInputId("owner-first-name", owner.id)
        ][0].getLocalValue() === ""
      ) {
        this.$refs[this.getInputId("owner-first-name", owner.id)][0].setValue(
          owner.firstName
        );

        this.$refs[this.getInputId("owner-last-name", owner.id)][0].setValue(
          owner.lastName
        );
      }
    },
    // Ref
    getInputId(name, id) {
      return `${name}-${id}`;
    },
    ownerFormClasses(ownerTabOpen) {
      return {
        "owner-form": true,
        open: ownerTabOpen,
      };
    },
    exportOwners() {
      const createdMoment = moment();
      const ownerFormat = {
        metadata: {
          source: "Mercala",
          content: "XML Report",
          version: "0.1",
          created: {
            year: createdMoment.format("YYYY"),
            month: createdMoment.format("MM"),
            day: createdMoment.format("DD"),
            hour: createdMoment.format("HH"),
            minute: createdMoment.format("mm"),
          },
        },
        data: {
          report_code: {
            year: createdMoment.format("YYYY"),
            month: createdMoment.format("MM"),
            day: createdMoment.format("DD"),
            sequence: "1-999",
          },
          description: "Transaction description",
          indicator_list: {
            indicator_row: {
              indicator_code: "Indicator code",
            },
          },
          transaction_list: {
            transaction_row: {
              transaction_code: "reporters unique transactionnumber. E.g. MTCN",
              // some more data, see XML exmaple
              subject_list: this.owners.map((owner) => {
                const birthMoment = moment(owner.birth_date);
                const personalIdExpirationMoment = moment(
                  owner.personalIdExpiration
                );
                const passportExpirationMoment = moment(
                  owner.passportExpiration
                );

                return {
                  subject_row: {
                    is_natural_person: owner.personType === 0 ? "Y" : "N",

                    naturalperson: {
                      lastname: owner.lastName,
                      firstnames: owner.firstName,
                      birth_date: {
                        year: birthMoment.format("YYYY"),
                        month: birthMoment.format("MM"),
                        day: birthMoment.format("DD"),
                      },
                      birth_place: {
                        city: owner.placeOfBirth,
                        country: owner.countryOfBirth,
                      },
                      occupation: owner.occupation,
                    },

                    idcard_list: [
                      {
                        idcard_row: {
                          idcard_code: owner.personalIdNumber,
                          idcard_type: "Drivers License / Sedula",
                          nationality: "coming soon",
                          issue_date: {
                            year: "coming soon",
                            month: "coming soon",
                            day: "coming soon",
                          },
                          issue_place: {
                            city: "",
                            country: "",
                          },
                          expiration_date: {
                            year: personalIdExpirationMoment.format("YYYY"),
                            month: personalIdExpirationMoment.format("MM"),
                            day: personalIdExpirationMoment.format("DD"),
                          },
                          image_list: {
                            image_row: {
                              image_filename: "coming_soon.png",
                              image_description: "coming soon",
                              image_base64: "coming soon base64 file",
                            },
                          },
                        },
                      },
                      {
                        idcard_row: {
                          idcard_code: "",
                          idcard_type: "Passport",
                          nationality: "coming soon",
                          issue_date: {
                            year: "coming soon",
                            month: "coming soon",
                            day: "coming soon",
                          },
                          issue_place: {
                            city: "",
                            country: "",
                          },
                          expiration_date: {
                            year: passportExpirationMoment.format("YYYY"),
                            month: passportExpirationMoment.format("MM"),
                            day: passportExpirationMoment.format("DD"),
                          },
                          image_list: {
                            image_row: {
                              image_filename: "coming_soon.png",
                              image_description: "coming soon",
                              image_base64: "coming soon base64 file",
                            },
                          },
                        },
                      },
                    ],

                    telephone_list: [
                      {
                        telephone_row: {
                          phonenumber: owner.homePhone,
                          internationalcallprefix: "coming soon",
                          country: "coming soon",
                        },
                      },
                      {
                        telephone_row: {
                          phonenumber: owner.mobilePhone,
                          internationalcallprefix: "coming soon",
                          country: "coming soon",
                        },
                      },
                      {
                        telephone_row: {
                          phonenumber: owner.businessPhone,
                          internationalcallprefix: "coming soon",
                          country: "coming soon",
                        },
                      },
                    ],

                    email_list: [
                      {
                        email_row: {
                          email_address: owner.personalEmail,
                        },
                      },
                      {
                        email_row: {
                          email_address: owner.businessEmail,
                        },
                      },
                    ],
                  },
                };
              }),
            },
          },
        },
      };

      this.xmlResult = js2xmlparser.parse("root", ownerFormat);
    },
  },
};
</script>

<style lang="scss">
.form-prototype {
  width: 100%;
  position: relative;
  padding: 25px;
  margin-bottom: 50px;

  .owner-add-container {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    align-items: flex-end;

    .form-text-input {
      margin: 0 25px 0 0;
      width: calc(100% - 200px);
    }

    .mercala-button {
      width: 175px;

      button {
        margin: 0;
      }
    }
  }

  .owners-container {
    .owner-form {
      border: 1px solid #000;
      border-radius: 6px;
      padding: 16px;
      margin-bottom: 25px;
      height: 50px;
      overflow: hidden;

      &.open {
        height: auto;
      }

      .owner-title {
        margin-bottom: 25px;
        cursor: pointer;
        width: 100%;
        height: 20px;
      }

      .owner-type-container {
        margin-bottom: 32px;
        display: flex;
        justify-content: center;

        label {
          cursor: pointer;
          &:first-child {
            margin-right: 25px;
          }
        }
      }

      .form-container {
        margin-bottom: 32px;

        h3 {
          margin-bottom: 8px;
          font-weight: 600;
        }
        .form {
          display: flex;

          .form-text-input,
          .form-datepicker-input,
          .form-select-input {
            margin-right: 16px;

            &:last-child {
              margin-right: 0;
            }
          }

          .form-column {
            width: calc(100% - 25px);
            margin-right: 25px;
            display: flex;
            flex-direction: column;

            &:last-child {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .form-tools {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  .xml-result {
    order: 1px solid #000;
    border-radius: 6px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
